import { useParams } from "react-router-dom"
import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { BASE_URL } from "../data/data"
import { SyncLoader } from "react-spinners";
import ResultDocument from "../components/ResultDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@mui/material";

const QuizResults = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)

   const columns = [
     {
       header: "S/N",
       accessor: "id",
       className: "hidden md:table-cell",
     },
     {
       header: "Name",
       accessor: "name",
       className: "hidden md:table-cell",
     },
     {
       header: "Submittion Time",
       accessor: "createdAt",
       className: "hidden md:table-cell",
     },
     {
       header: "Score",
       accessor: "mark",
       className: "hidden md:table-cell",
     },
   ];
  
  const fetchData =useCallback(async () => {
    if (id) {
      try {
        const res = await axios.get(BASE_URL + `result/${id}`);
        if (res.status === 200) {
          setResults(res.data.results);
          setLoading(false)
        } else {
          setError(true)
        }
      } catch (error) {
        setError(true);
      }
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [id, fetchData]);

  return error?<div>Error Fetching Quizez</div>:loading ? (
    <div className="spinner-container">
      <SyncLoader loading size={50} color="#46a5de" />
    </div>
  ) : (
    <div>
      <table className="w-full mt-4">
        <thead>
          <tr className="text-left text-gray-500 text-sm">
            {columns.map((col) => (
              <th key={col.accessor} className={col.className}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map(({ name, createdAt, mark, result }, id) => (
            <tr
              key={id}
              className="border border-gray-200 even:bg-slate-50 text-sm hover:bg-[#F1F0FF] p-2"
            >
              <td className="text-gray-600 text-sm text-center">{id + 1}</td>
              <td className="text-gray-600 text-sm">{name}</td>
              <td className="text-gray-600 text-sm">{`${new Date(
                createdAt
              ).toLocaleDateString()} ${new Date(
                createdAt
              ).toLocaleTimeString()}`}</td>
              <td className="text-gray-600 text-sm">{mark}</td>
              <td>
                <PDFDownloadLink
                  document={
                    <ResultDocument
                      marks={{ result, mark }}
                      options={[]}
                      name={name}
                      subject={"Title"}
                    />
                  }
                  fileName="quiz-result.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled
                      >
                        Loading Result...
                      </Button>
                    ) : (
                      <Button variant="contained" size="medium" color="success">
                        Download Result
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default QuizResults