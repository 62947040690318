import { useState } from "react";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../data/data";
import { useQuizStore } from "../components/store";

const TakeQuiz = () => {
  const [name, setName] = useState("")
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const {setData} = useQuizStore()

  const onSubmit = async () => {
    try {
      if (!id || !name) {
        enqueueSnackbar("Name and Exam ID required!!", {
          variant: "error",
          autoHideDuration: 2000,
        });
      } else {
        setLoading(true)
        const res = await axios.get(`${BASE_URL}quiz/${id}`);
        if (res.status === 200) {
          setLoading(false);
          setData({ ...res.data, name })
          navigate("/take-quiz/quiz")
          // navigate("/quiz", { state: { category: res.data.subject, difficulty:res.data.difficulty, name, quizId:id, limit:res.data.numQuestions, type:"setup" } });
        } else {
          setLoading(false)
          enqueueSnackbar("Error fetching Quiz!!", {
            variant: "error",
            autoHideDuration: 2000,
          });
        };
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      enqueueSnackbar("Error fetching Quiz!!", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <div className="flex flex-col w-full">
      <SnackbarProvider />
      <label className="mt-3">Name</label>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        type="text"
        className="question-inputs"
      />
      <label className="mt-3">Exam ID</label>
      <input
        value={id}
        onChange={(e) => setId(e.target.value)}
        type="text"
        className="question-inputs"
      />
       <button
          disabled={loading}
          onClick={onSubmit}
          className={`p-2 mt-4 rounded-sm cursor-pointer shadow-sm shadow-black text-white relative hover:bg-green-300 ${
            loading ? "bg-green-200" : "bg-green-500"
          }`}
        >{loading?"Fetching Questions":"Take Quiz"}</button>
    </div>
  );
};
export default TakeQuiz;
