import React from "react";
import { Outlet } from "react-router-dom";
import Zenkleus from '../assets/Zenkleus.png'
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";

const RootLayout = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const user = JSON.parse(localStorage.getItem("userData"))
  // if (!user) {
  //   navigate("/login");
  // }
  return (
    <div className="root-layout bg-[#fffffff] sm:min-h-[100vh] sm:min-w-[750px]">
      {!location.pathname.includes("take-quiz/quiz") && (
        <header>
          <div>
            <h1 className="sm:ml-0 text-5xl italic font-extrabold">
              Skoolbod Quiz
            </h1>
          </div>
          {user ? (
            <div className="flex gap-2 mt-2">
              <Button
                onClick={() => navigate("/add-question")}
                variant={
                  location.pathname === "/add-question"
                    ? "contained"
                    : "outlined"
                }
                size="small"
              >
                Upload Questions
              </Button>
              <Button
                className=""
                onClick={() => navigate("/my-quiz")}
                variant={
                  location.pathname === "/my-quiz" ? "contained" : "outlined"
                }
              >
                My Quizzez
              </Button>
              <Button
                variant={
                  location.pathname === "/questions" ? "contained" : "outlined"
                }
                onClick={() => navigate("/questions")}
              >
                Questions
              </Button>
              <Button
                onClick={() => {
                  localStorage.removeItem("userdata");
                  navigate("/login");
                }}
                variant="outlined"
              >
                Logout
              </Button>
            </div>
          ) : (
            <div className="flex gap-3 mt-3">
              <Button onClick={() => navigate("/login")} variant="outlined">
                Login
              </Button>
              <Button onClick={() => navigate("/sign-up")} variant="outlined">
                Register
              </Button>
            </div>
          )}
        </header>
      )}
      <main className="content">
        <SnackbarProvider />
        <Outlet />
      </main>
      <footer className="flex w-full">
        <div className="flex flex-row items-center ">
          <span className="font-black text-xl sm:text-3xl ml-6 sm:ml-20">
            Powered by:{" "}
          </span>
          <a
            target="blank"
            href="https://zenkleus.com"
            style={{ cursor: "pointer" }}
          >
            <img
              className="w-[150px] sm:w-[250px]"
              src={Zenkleus}
              alt="Zenkleus"
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default RootLayout;
