import React, { useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//layouts
import RootLayout from "./layouts/RootLayout";
import ErrorLayout from "./layouts/ErrorLayout";

//pages
import Home from "./pages/Home";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";
import AddQuestion from "./pages/AddQuestion";
import CreateQuiz from "./pages/CreateQuiz";
import TakeQuiz from "./pages/TakeQuiz";
import Login from "./pages/Login";
import Render from "./pages/Render";
import SignUp from "./pages/SignUp";
import ViewResults from "./pages/ViewResults";
import Timer from "./pages/Timer";
import Montessori from "./pages/Montessori";
import Questions from "./pages/Questions";
import MyQuiz from "./pages/MyQuiz";
import StudentQuiz from "./pages/StudentQuiz";
import QuizResults from "./pages/QuizResults";

const App = () => {
  const [questions, setQuestions] = useState([]);
  const [score, setScore] = useState(0);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<RootLayout />} errorElement={<ErrorLayout />}>
          <Route index element={<Home />} />
          <Route
            path="quiz"
            element={
              <Quiz
                questions={questions}
                score={score}
                setScore={setScore}
                setQuestions={setQuestions}
              />
            }
          />
          <Route path="my-quiz/results/:id" element={<QuizResults />} />
          <Route path="take-quiz/quiz" element={ <StudentQuiz /> } />
          <Route path="my-quiz" element={<MyQuiz />} />
          <Route path="add-question" element={<AddQuestion />} />
          <Route path="create-quiz" element={<CreateQuiz />} />
          <Route path="take-quiz" element={<TakeQuiz />} />
          <Route path="render" element={<Render />} />
          <Route path="result" element={<Result score={score} />} />
          <Route path="quiz-results" element={<ViewResults />} />
          <Route path="/timer" element={<Timer />} />
          <Route path="/montessori" element={<Montessori />} />
          <Route path="/questions" element={<Questions />} />
        </Route>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="login" element={<Login />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
