const Difficulty = [
  { label: "Easy", value: "Easy" },
  { label: "Medium", value: "Medium" },
  { label: "Hard", value: "Hard" },
];

// const bf = [
//   { category: "General Knowledge", value: 9 },
//   { category: "Music", value: 12 },
//   { category: "Musicals and Theaters", value: 13 }, //only medium, less than 15 in medium
//   { category: "Science and Nature", value: 17 },
//   { category: "Computer", value: 18 },
//   { category: "Mathematics", value: 19 }, //less than 15 questions for hard
//   { category: "Mythology", value: 20 }, //less than 15 questions for hard
//   { category: "Sports", value: 21 },
//   { category: "Geography", value: 22 },
//   { category: "History", value: 23 },
//   { category: "Politics", value: 24 },
//   { category: "Art", value: 25 },
//   { category: "Animals", value: 27 },
//   { category: "Cartoons and Animations", value: 32 },
// ];

const Categories = [
  { category: "Chemistry", value: "Chemistry_waec_obj_1988_2021" },
  {
    category: "English Language",
    value: "English Language_jamb_obj_1988_2021",
  },
  {
    category: "Agricultural Science",
    value: "jamb_obj_Agricultural Science_1988",
  },
  {
    category: "General knowledge",
    value: "General knowledge",
  },
  { category: "Kindergarten", value: "Kindergaten" },
];

export const kQuestions = [
  {
    question: "Which Image Has the Shape of a Triangle?",
    options: ["Image A", "Image B", "Image C", "Image D"],
    _id: "98876544322456767",
    images: [
      "/images/circle.jpeg",
      "/images/rectangle.png",
      "/images/square.png",
      "/images/triangle2.jpeg",
    ],
    category:"Montessori"
  },
  {
    question: "Of the items above, which one can be eaten?",
    options: ["Image A", "Image B", "Image C", "Image A and C"],
    _id: "988765443224567674",
    images: [
      "/images/pizza.jpeg",
      "/images/ball.jpeg",
      "/images/doughnot.jpeg",
      "/images/pencil.png",
    ],
    category:"Montessori"
  },
];

// const tQues = {
//   question:
//     "JAMB 2007   Choose the option opposite in meaning to word underlined?  For anything to do with academic work, he has a great <ins>aversion?</ins>",
//   options: ["conversion", "attention", "contempt", "predilection", "__"],
//   _id: "663a04c3dbcda87cb32616b9",
// };

const BASE_URL = "https://quiz-server-rjr2.onrender.com/api/v1/";
// const BASE_URL = "http://localhost:3000/api/v1/";

const optionsMap = ["A","B","C","D","E"]
export {Difficulty, optionsMap, BASE_URL}
export default Categories
