import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Categories from "../data/data";
import skoolbod from "../assets/skolbod_gold.png";
// import bg from "../assets/display.png"
import zenkleus from "../assets/Zenkleus.png";

const ResultDocument = ({ marks, options, name, subject }) => {
  // console.log(marks, options, name, subject)
  const date = new Date()
  const optionLabelToId = { "A": 0, "B": 1, "C": 2, "D": 3, "E": 4 }
  // const sbj = Categories.filter(({value})=>value===subject)[0].category
  const questionsBreakdown = marks.result.map(({
    question,
    answer,
    submittedAnswer,
  }, id) => {
    // const [correctValue, submittedValue] = [
    //   options[id][optionLabelToId[answer]],
    //   options[id][optionLabelToId[submittedAnswer]],
    // ];
    console.log(question, answer, submittedAnswer)
    return (
      <View
        key={id}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          fontSize: "12px",
          width: "100%",
        }}
      >
        <Text style={{ width: "50%" }}>{question}</Text>
        <Text style={{ width: "25%" }}>{ answer }</Text>
        <Text style={{ width: "25%" }}>{submittedAnswer}</Text>
      </View>
    );
  });
  return (
    <Document>
      <Page size="A4" style={{}}>
        <View style={{ position: "relative" }}>
          {/* <Image
            src={bg}
            style={{ width: "100vw", height: "100vh", position: "absolute" }}
          /> */}
          <View
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              verticalAlign: "sub",
              borderBottom: "2px solid #CAAB67",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Image src={skoolbod} style={{ width: "180px" }} />
            <Text style={{ fontSize: "20px", color: "darkgray" }}>
              SKOOLBOD QUIZES
            </Text>
          </View>
          <View
            style={{
              marginLeft: "5%",
              marginRight: "5%",
              display: "flex",
              flexDirection: "column",
              marginTop: "5%",
              gap: "10px",
              fontSize:"12px"
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "sub",
              }}
            >
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Name:</Text>
                <Text
                  style={{
                    color: "black",
                    alignItems: "center",
                    verticalAlign: "sub",
                    fontSize:"11px",
                  }}
                >
                  {name}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text>Date:</Text>
                <Text>{`${date.getDate()} - ${date.getMonth()+1} - ${date.getFullYear()}`}</Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "sub",
              }}
            >
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text style={{ fontSize: "12px" }}>Subject:</Text>
                <Text
                  style={{
                    color: "#CAAB67",
                  }}
                >
                  {subject}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text>Difficulty Level:</Text>
                <Text
                  style={{
                    color: "#CAAB67",
                  }}
                >
                  Medium
                </Text>
              </View>
            </View>
            <View
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                gap: "10px",
              }}
            >
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                  fontSize:"12px"
                }}
              >
                <Text>Number Of Questions Attempted:</Text>
                <Text
                  style={{
                    color: "blue",
                  }}
                >
                  {marks.result.length}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text>Number Of Questions Answered Correctly:</Text>
                <Text
                  style={{
                    color: "blue",
                  }}
                >
                  {marks.mark}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: "5%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "row",
                  verticalAlign: "sub",
                }}
              >
                <Text>Percentage Score:</Text>
                <Text
                  style={{
                    color: "blue",
                  }}
                >
                  {(marks.mark/marks.result.length)*100}%
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                gap: "10px",
              }}
            >
              <Text>Questions Breakdown</Text>
              <View style={{ display: "flex", flexDirection:"row", fontSize:"13px", fontWeight:"extrabold" }}>
                <Text style={{width:"50%"}}>Question</Text>
                <Text style={{width:"25%"}}>Submitted Answer</Text>
                <Text style={{width:"25%"}}>Correct Answer</Text>
              </View>
              {questionsBreakdown}
            </View>
          </View>
          <View
            style={{
              position: "absolute",
              top: "205%",
              width: "100%",
              borderTop: "2px solid gray",
              display: "flex",
              flexDirection: "row",
              verticalAlign: "sub",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingLeft: "25%",
            }}
          >
            <Text>POWERED BY:</Text>
            <Image src={zenkleus} style={{ width: "180px" }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ResultDocument;
