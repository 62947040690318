import Modal from "react-modal";

const customStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

let subtitle = "Sbt";

const ModalComp = ({ open, close, content, title }) => {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    close(false);
  }
  return (
    <Modal
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete Question"
    >
      <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{ title}</h2>
      <button className="absolute top-0 right-2" onClick={closeModal}>
        X
      </button>
      {content}
    </Modal>
  );
};
export default ModalComp;
