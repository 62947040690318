import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../data/data";
import axios from "axios";
import { TrashIcon } from "@radix-ui/react-icons";
import { Button } from "@mui/material";
import ModalComp from "../components/ModalComp";
import { SnackbarProvider, enqueueSnackbar } from "notistack";


const Questions = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const cDate = (new Date()).toLocaleString()
  const navigate = useNavigate();
  if (!user) navigate("/login")
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState({})
  const [questionToDelele, setQuestionToDelete] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [title, setTitle] = useState("")
  const [quizDate, setQuizDate] = useState(cDate)
  const [subject, setSubject] = useState("")
  const [allowedTime, setAllowedTime] = useState("")
  const [currentQues, setCurrentQues] = useState(questions?questions[0]:{})
  const [openQues, setOpenQues] = useState(false)

  let subtitle = "Sbt"
  const deleteQuestion = async () => {
    const res = await axios.delete(BASE_URL + "question/" + `${questionToDelele}`)
    if (res.status === 201) {
      setRefetch(true)
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const fetchQuestions = async () => {
    try {
      if (user) {
        const res = await axios.get(`${BASE_URL}admin/questions/${user.id}`);
        if (res.status === 200) {
          setQuestions(res.data.questions);
          setIsLoading(false);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      } else {
        navigate("/login");
      }
      
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      
    }
  };

  const createQuiz = async () => {
    const questions = Object.entries(selectedQuestions).filter(val => val[1] == true).map(val => val[0])
    try { 
      const res = await axios.post(`${BASE_URL}quiz/${user.id}`, {
        subject,numQuestions: questions.length,
        title, questions, quizDate, dueDate: quizDate,
        allowedTime
      });
      if (res.status === 201) {
        console.log("quiz created")
        enqueueSnackbar("Quiz Created!!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Quiz Creation Failed!!", {
          variant: "error",
          autoHideDuration: 3000,
        });
        console.log("quiz not created")
      }
    } catch (error) {
      console.log("quiz creation failed")
    }
  }

  const questionCont = (currentQues ? (
    <div>
      <h2>Question {currentQues.question}</h2>
      {currentQues.options.map(({ label, value }, id) => (
        <div className="flex gap-2 align-middle items-center place-content-center" key={id}>
          <h2 className="text-sm text-slate-700">Option {label}:</h2>
          <p className="text-xs">{value}</p>
        </div>
      ))}
    </div>
  ):(<div></div>))

  const mcontent = (<><h2 ref={(_subtitle) => (subtitle = _subtitle)}>Delete Question</h2>
        <button className="absolute top-0 right-2" onClick={closeModal}>
          X
        </button>
        <div>Are you sure you want to delete the question?</div>
        <div className="flex gap-2 mt-4">
          <button
            onClick={() => {
              deleteQuestion();
              closeModal();
            }}
            className="text-white text-sm bg-red-600 p-2 rounded-sm"
          >
            Continue
          </button>
          <button
            onClick={closeModal}
            className="text-white text-sm bg-green-600 p-2 rounded-sm"
          >
            Close
          </button>
    </div></>)
  const scontent = (
    <>
      <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Set-Up Quiz</h2>
      <button
        className="absolute top-0 right-2"
        onClick={() => setOpenCreate(false)}
      >
        X
      </button>
      <h2 className="text-sm text-gray-500">
        Create Quiz With Selected Questions
      </h2>
      <div className="w-full flex flex-col gap-3">
        <div className="text-sm text-slate-500 flex gap-2 w-full align-middle items-center">
          <label htmlFor="title" className="">
            Quiz Title
          </label>
          <input
            className="border border-gray-500 p-1 min-w-16"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="text-sm text-slate-500 flex gap-2 w-full align-middle items-center">
          <label htmlFor="date">Quiz Date and Time</label>
          <input
            className="border border-gray-500 p-1 min-w-16"
            aria-label="Date and time"
            name="date"
            type="datetime-local"
            value={quizDate}
            onChange={(e) => setQuizDate(e.target.value)}
          />
        </div>
        <div className="text-sm text-slate-500 flex gap-2 w-full align-middle items-center">
          <label htmlFor="subject">Subject</label>
          <input
            className="border border-gray-500 p-1 min-w-16"
            aria-label="Date and time"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="text-sm text-slate-500 flex gap-2 w-full align-middle items-center">
          <label htmlFor="time">Allowed TIme in Minutes</label>
          <input
            className="border border-gray-500 p-1 min-w-16"
            name="time"
            value={allowedTime}
            onChange={(e) => setAllowedTime(e.target.value)}
          />
        </div>
        <div className="flex gap-2 mt-4">
          <button
            onClick={() => {
              createQuiz();
              closeModal();
            }}
            className="text-white text-sm bg-green-600 p-2 rounded-sm"
          >
            Create
          </button>
          <button
            onClick={closeModal}
            className="text-white text-sm bg-red-600 p-2 rounded-sm"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (questions.length !== 0) {
      const sQ = {}
      questions.forEach((q, index) => { 
        sQ[q._id] = false
      })
      setSelectedQuestions(sQ)
    }
  },[questions])

  useEffect(() => {
    fetchQuestions()
  }, [refetch]);

  const columns = [
    {
      header: "",
      accessor: "idw",
      className: "hidden md:table-cell",
    },
    {
      header: "S/N",
      accessor: "id",
      className: "hidden md:table-cell",
    },
    {
      header: "Question",
      accessor: "question",
      className: "hidden md:table-cell",
    },
    {
      header: "Subject",
      accessor: "subject",
      className: "hidden md:table-cell",
    },
    {
      header: "Difficulty",
      accessor: "difficulty",
      className: "hidden md:table-cell",
    },
  ];
  return isLoading ? (
    <div>Fetching...</div>
  ) : isError ? (
    <div>Error Occurs</div>
  ) : (
    <>
      <ModalComp open={modalIsOpen} close={setIsOpen} content={mcontent} title={'Delete Question'}/>
      <ModalComp open={openCreate} close={setOpenCreate} content={scontent} title={'Create Quiz'} />
      <ModalComp open={openQues} close={setOpenQues} content={questionCont} title={'Question'} />
      {Object.values(selectedQuestions).filter((v) => v === true).length !==
        0 && (
        <button
          onClick={() => setOpenCreate(true)}
          className="text-white bg-green-600 p-1 rounded-sm hover:bg-green-300 cursor-pointer"
        >
          Create Quiz With Questions
        </button>
      )}
      <table className="w-full mt-4">
        <thead>
          <tr className="text-left text-gray-500 text-sm">
            {columns.map((col) => (
              <th key={col.accessor} className={col.className}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questions.length !== 0 &&
            questions.map(({ question, subject, difficulty, _id }, id) => (
              <tr
                key={id}
                className="border border-gray-200 even:bg-slate-50 text-sm hover:bg-[#F1F0FF] p-2"
              >
                <td className="align-middle items-center">
                  <input
                    type="checkbox"
                    checked={
                      selectedQuestions[_id] ? selectedQuestions[_id] : false
                    }
                    onChange={() => {
                      setSelectedQuestions({
                        ...selectedQuestions,
                        [_id]: !selectedQuestions[_id],
                      });
                    }}
                  />
                </td>
                <td className="text-gray-600 text-sm text-center p-2">{id + 1}</td>
                <td
                  className="text-gray-600 text-sm hover:text-gray-400 cursor-pointer"
                  onClick={() => {
                    setCurrentQues(questions[id])
                    setOpenQues(true)
                  }}
                >
                  {question}
                </td>
                <td className="text-gray-600 text-sm">{subject}</td>
                <td className="text-gray-600 text-sm">{difficulty}</td>
                <td className="">
                  <button
                    onClick={() => {
                      setQuestionToDelete(_id);
                      openModal();
                    }}
                  >
                    <TrashIcon
                      color="red"
                      style={{ hover: { innerWidth: 30 } }}
                    />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
        {/* <tbody>{data.map((item) => renderRow(item))}</tbody> */}
      </table>
      {questions.length === 0 && (
        <div className="text-center w-full mt-4 text-gray-500">
          No Questions Found.
        </div>
      )}
    </>
  );
};
export default Questions;
