import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Question from "../components/Questions";
import {SyncLoader} from 'react-spinners'
import axios from "axios";
import { BASE_URL } from "../data/data";
import { useQuizStore } from "../components/store";

const Quiz = ({ score, setScore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useQuizStore()

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [choice, setChoice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("nml");
  const [name, setName] = useState(data?data.name:"");
  const [quizId, setQuizId] = useState(data?data._id:"");
  const state = location.state;
  
  useEffect(() => {
    if (state !=null) {
      const category = state["category"];
      const difficulty = state["difficulty"];
      setType(state["type"]);
      setName(state["name"])
      setQuizId(state["quizId"]);
      const limit = state["limit"]

      window.history.replaceState({}, "");
  
      if (category && difficulty) {
        fetchQuestions(category, difficulty, limit);
      } else {
        navigate(-1); 
      }   
    } else if (data) {
      setType("setup")
      fetchQuizQuestions()
    }
    else {
      // navigate(-1)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (questions.length > 0) {
      setChoice([
        currentQuestion.options,
      ]);
    }
  }, [currentQuestion, questions]);


  const shuffleChoiceHandler = (choices) => {
    return choices.sort(() => Math.random() - 0.5);
  };

  const reformatQuestions = (fetchedQuestions) => {
    return fetchedQuestions.map(({ question, options, _id }) => {
      return { question, options: options.map(({ value }) => value), _id };
    });
  }

  const fetchQuizQuestions = async () => {
    console.log(data.questions)
    try {
      const res = await axios.get(`${BASE_URL}question/quiz-questions/${data._id}` );
      if (res.status === 200) {
        setQuestions(reformatQuestions(res.data.questions));
        setLoading(false)
      } else {
        // console.log(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
    
  }

  // https://opentdb.com/api.php?amount=10&category=19&type=multiple
  const fetchQuestions = async (category, difficulty, limit) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}question?${
          category && `&subject=${category}&difficulty=${difficulty}&limit=${limit}`
        }`
      );
      setQuestions(reformatQuestions(data.questions));
      setLoading(false); // Set loading state to false after fetching questions

    } catch (error) {
      throw new Error("Questions could not be fetched");
    }
  };
  return (
    <div className="quiz">
      {!loading &&
        questions.length > 0 && ( // Check if loading is false and questions are available
          <>
            <div>
              <h3 className="quiz-category">
                {questions[currentQuestion].category}
              </h3>
            </div>
            <Question
              questions={questions}
              type={state?state.type? state.type: type:type}
              name={name}
              quizId={quizId}
              subject={state ? state["category"] : data.subject}
              time={data?data.allowedTime:10}
            />
          </>
        )}
      {loading && (
        <div className="spinner-container">
          <SyncLoader loading size={50} color="#46a5de" />
        </div>
      )}
    </div>
  );
};

export default Quiz;

// questions, name, type, quizId, subject, time;