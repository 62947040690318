import { useState, useEffect, useCallback } from "react";
import { BASE_URL } from "../data/data";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@radix-ui/react-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy, FaBookReader } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";

const MyQuiz = () => {
  const user = JSON.parse(localStorage.getItem("userData"))
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [quizez, setQuizez] = useState([])
  const [refetch, setRefetch] = useState(false)
  const navigate = useNavigate()

   const columns = [
     {
       header: "S/N",
       accessor: "id",
       className: "hidden md:table-cell",
     },
     {
       header: "Title",
       accessor: "title",
       className: "hidden md:table-cell",
     },
     {
       header: "Quiz Date",
       accessor: "quizDate",
       className: "hidden md:table-cell",
     },
     {
       header: "Taken",
       accessor: "taken",
       className: "hidden md:table-cell",
     },
   ];
  
  const deleteQuiz = async (id) => {
    const res = await axios.delete(
      BASE_URL + `quiz/${id}`
    );
    if (res.status === 201) {
      setRefetch(!refetch);
    }
  };

  const fetchQuizez = useCallback(async () => {
    try {
      if (user) {
        const res = await axios.get(`${BASE_URL}quiz/user/${user.id}`);
        if (res.status === 200) {
          setQuizez(res.data.quizez);
          setIsLoading(false);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  },[user, navigate])

  useEffect(() => {
    fetchQuizez();

  }, [refetch, fetchQuizez]);

  const onCopyText = () => {
    enqueueSnackbar("Copied to clipboard!!", {
      variant: "success",
      autoHideDuration: 2000,
    }); // Reset status after 2 seconds
  };

  return isError ? (
    <div>Error Occurs</div>
  ) : isLoading ? (
    <div>Fetching Quizez...</div>
  ) : (
    <div>
      <table className="w-full mt-4">
        <thead>
          <tr className="text-left text-gray-500 text-sm">
            {columns.map((col) => (
              <th key={col.accessor} className={col.className}>
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {quizez.map(({ title, _id, quizDate, taken }, id) => (
            <tr
              key={id}
              className="border border-gray-200 even:bg-slate-50 text-sm hover:bg-[#F1F0FF] p-2"
            >
              <td className="text-gray-600 text-sm text-center">{id + 1}</td>
              <td className="text-gray-600 text-sm">{title}</td>
              <td className="text-gray-600 text-sm">
                {new Date(quizDate).toLocaleDateString()}
              </td>
              <td className="text-gray-600 text-sm">{taken}</td>
              <td className="flex gap-1 align-middle items-center content-center place-content-center">
                <button
                  // onClick={() => {
                  //   setQuestionToDelete(_id);
                  //   openModal();
                  // }}
                  className="bg-white text-white shadow-sm shadow-black hover:bg-green-200"
                >
                  <TrashIcon
                    color="red"
                    style={{ hover: { innerWidth: 60, outerWidth: 80 } }}
                    onClick={() => {
                      deleteQuiz(_id);
                    }}
                  />
                </button>
                <button
                  onClick={() => navigate(`results/${_id}`)}
                  className="p-2 hover:bg-green-200"
                >
                  <FaBookReader />
                </button>
                <div className="hover:bg-green-200">
                  <CopyToClipboard text={_id} onCopy={onCopyText}>
                    <button className="ml-2 text-green-500">
                      <FaRegCopy />
                    </button>
                  </CopyToClipboard>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default MyQuiz