import React, { useState, useRef, useEffect } from "react";

const Timer = ({time, setShowResult, ShowResult, title}) => {
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total > 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else if (total === 0) {
      // if(!ShowResult)setShowResult(true)
      if (setShowResult) {
        setShowResult(true)
      }
      // window.history.replaceState({}, "");
    }
  };

  const clearTimer = (e) => {
    const minutes = time >= 10?`${time}`:`0${time}`
    setTimer(`${minutes}:00`);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + (time*60));
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);


  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <h1 style={{ color: "green" }}>{ title}</h1>
      <h2>{timer}</h2>
    </div>
  );
};

export default Timer;
